<template>
  <svg
    width="420"
    height="91"
    viewBox="0 0 420 91"
    xmlns="http://www.w3.org/2000/svg"
    fill="#1E1E1E"
  >
    <title>Klimate Logo</title>
    <path
      d="M126.764 21.9195C132.813 21.9195 137.717 17.0127 137.717 10.9598C137.717 4.90686 132.813 0 126.764 0C120.714 0 115.811 4.90686 115.811 10.9598C115.811 17.0127 120.714 21.9195 126.764 21.9195Z"
    />
    <path
      d="M82.3857 71.2884V4.83984H101.414V70.2164C101.414 73.4268 102.844 74.6186 105.813 74.6186H109.848V88.8971H100.223C88.0959 88.8971 82.3857 83.3031 82.3857 71.2884Z"
    />
    <path d="M135.691 29.1152H116.543V88.9036H135.691V29.1152Z" />
    <path
      d="M146.423 29.076H164.733V35.6222C167.941 30.9806 173.059 27.4395 181.265 27.4395C189.944 27.4395 195.535 31.8131 198.629 37.1675C202.077 32.0526 208.739 27.4395 217.891 27.4395C231.089 27.4395 240.013 35.0292 240.013 49.9007V88.8644H221.105L221.225 52.6378C221.225 45.8578 218.489 42.0487 211.947 42.0487C205.405 42.0487 202.196 46.6903 202.196 54.1888V88.8644H184.359V52.6378C184.359 45.8578 181.624 42.0487 174.968 42.0487C168.312 42.0487 165.457 46.9298 165.457 54.1888V88.8644H146.429V29.076H146.423Z"
    />
    <path
      d="M245.543 58.8658C245.543 40.3049 256.724 27.4805 273.729 27.4805C283.719 27.4805 290.734 31.9739 294.182 36.376V29.117H312.731V88.9054H294.182V81.002C291.686 84.4519 284.904 90.5191 273.843 90.5191C256.718 90.5191 245.537 77.9057 245.537 58.8658H245.543ZM279.673 76.1209C288.471 76.1209 294.655 69.5747 294.655 58.8658C294.655 48.1569 288.471 41.6107 279.673 41.6107C270.874 41.6107 264.81 48.1569 264.81 58.8658C264.81 69.5747 270.634 76.1209 279.673 76.1209Z"
    />
    <path d="M19.9798 88.9028H0V4.83984H19.9798V88.9028Z" />
    <path
      d="M319.846 14.5459H338.754V29.1152H352.152V42.0879H338.754V68.3183C338.754 73.0797 341.011 74.6251 345.416 74.6251H352.158V88.9036H339.712C326.867 88.9036 319.851 82.0038 319.851 69.1509V14.5459H319.846Z"
    />
    <path
      d="M355.84 58.746C355.84 39.9456 368.206 27.4805 388.066 27.4805C409.949 27.4805 421.603 42.2094 419.82 62.9144H374.389C374.629 72.1977 379.621 77.7859 387.827 77.7859C395.201 77.7859 398.769 74.2163 400.672 69.6944H418.868C416.611 79.6905 407.214 90.5191 388.066 90.5191C367.613 90.5191 355.84 77.7859 355.84 58.746ZM401.031 52.4393C400.678 43.9886 395.087 39.7061 388.066 39.7061C380.339 39.7061 375.58 44.228 374.748 52.4393H401.031Z"
    />
    <path
      d="M54.9251 88.9085L28.1467 53.6855C25.0865 49.6597 25.0865 44.0886 28.1467 40.0628L54.9251 4.83984H79.1562L50.3091 41.3971C47.7732 44.6075 47.7732 49.1408 50.3091 52.3512L79.1562 88.9085H54.9251Z"
    />
  </svg>
</template>
