import './assets/main.css';

import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/link-context';
import { createAuth0 } from '@auth0/auth0-vue';
// eslint-disable-next-line no-restricted-imports
import * as Sentry from '@sentry/vue';
import { ApolloClients } from '@vue/apollo-composable';
import { createPinia } from 'pinia';
import PrimeVue from 'primevue/config';
import Tailwind from 'primevue/passthrough/tailwind';
import { createApp } from 'vue';

import App from './App.vue';
import router from './router';
import { useNotificationStore } from './stores/notifications';

const app = createApp(App);

app.use(PrimeVue, { unstyled: true, pt: Tailwind });
app.use(createPinia());
app.use(router);
const auth0 = createAuth0({
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  authorizationParams: {
    redirect_uri: import.meta.env.VITE_AUTH0_CALLBACK_URL,
    audience: import.meta.env.VITE_AUTH0_AUDIENCE,
  },
  cacheLocation: 'localstorage',
});
app.use(auth0);

const { pushNotification } = useNotificationStore();
const errorLink = onError(({ graphQLErrors }) => {
  pushNotification({
    type: 'error',
    title: 'The application has encountered an unknown error.',
    message: graphQLErrors?.map((e) => e.message).join(', ') ?? '',
  });
});

app.provide(ApolloClients, {
  default: new ApolloClient({
    link: from([
      errorLink,
      setContext(async () => {
        const token = await auth0.getAccessTokenSilently().catch((reason) => {
          if (['login_required', 'consent_required'].includes(reason.error)) {
            auth0.loginWithRedirect();
          }
          throw reason;
        });
        return { headers: { Authorization: `Bearer ${token}` } };
      }).concat(
        createHttpLink({
          uri: import.meta.env.VITE_BORON_GRAPHQL_API_URL,
        }),
      ),
    ]),
    cache: new InMemoryCache(),
    connectToDevTools: false,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  }),
});

if (import.meta.env.PROD)
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/all-boron-dev\.azurewebsites\.net\/api/,
          /^https:\/\/boron\.klimate\.co\/api/,
          /^https:\/\/admin\.klimate\.co\/api/,
        ],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    app,
  });

app.mount('#app');
