import { defineStore } from 'pinia';
import { ref } from 'vue';

export type Notification = {
  type: 'success' | 'error' | 'warning' | 'info';
  title: string;
  message: string;
};

export const useNotificationStore = defineStore('notifications', () => {
  const closeTimeoutId = ref<number>();
  const openTimeoutId = ref<number>();
  const notification = ref<Notification | undefined>();
  const cancelTimeouts = () => {
    clearTimeout(closeTimeoutId.value);
    clearTimeout(openTimeoutId.value);
    closeTimeoutId.value = undefined;
  };
  const closeNotification = () => {
    cancelTimeouts();
    notification.value = undefined;
  };
  const pushNotification = (n: Notification, delayInMs: number = 4_000) => {
    cancelTimeouts();
    if (notification.value) {
      notification.value = undefined;
      openTimeoutId.value = setTimeout(() => (notification.value = n), 100);
    } else {
      notification.value = n;
    }
    if (delayInMs >= 0) {
      closeTimeoutId.value = setTimeout(closeNotification, delayInMs);
    }
  };
  return { notification, closeNotification, pushNotification };
});
