export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/:path(.*)',
    name: '/[...path]',
    component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/[...path].vue'),
    /* no children */
  },
  {
    path: '/calculation-methods',
    /* internal name: '/calculation-methods' */
    /* no component */
    children: [
      {
        path: '',
        name: '/calculation-methods/',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/calculation-methods/index.vue'),
        /* no children */
      },
      {
        path: 'new',
        name: '/calculation-methods/new',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/calculation-methods/new.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/clients',
    /* internal name: '/clients' */
    /* no component */
    children: [
      {
        path: '',
        name: '/clients/',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/clients/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        name: '/clients/[id]',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/clients/[id].vue'),
        /* no children */
      },
      {
        path: 'new',
        name: '/clients/new',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/clients/new.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/composition-templates',
    /* internal name: '/composition-templates' */
    /* no component */
    children: [
      {
        path: '',
        name: '/composition-templates/',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/composition-templates/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        name: '/composition-templates/[id]',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/composition-templates/[id].vue'),
        /* no children */
      },
      {
        path: 'new',
        name: '/composition-templates/new',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/composition-templates/new.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/demand-orders',
    /* internal name: '/demand-orders' */
    /* no component */
    children: [
      {
        path: '',
        name: '/demand-orders/',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/demand-orders/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        name: '/demand-orders/[id]',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/demand-orders/[id].vue'),
        /* no children */
      },
      {
        path: ':orderId/items/:itemId',
        name: '/demand-orders/[orderId].items.[itemId]',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/demand-orders/[orderId].items.[itemId].vue'),
        /* no children */
      },
      {
        path: ':orderId/items/new',
        name: '/demand-orders/[orderId].items.new',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/demand-orders/[orderId].items.new.vue'),
        /* no children */
      },
      {
        path: ':orderId/payments/:paymentId',
        name: '/demand-orders/[orderId].payments.[paymentId]',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/demand-orders/[orderId].payments.[paymentId].vue'),
        /* no children */
      },
      {
        path: ':orderId/payments/:paymentId/invoice/:invoiceNumber',
        name: '/demand-orders/[orderId].payments.[paymentId].invoice.[invoiceNumber]',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/demand-orders/[orderId].payments.[paymentId].invoice.[invoiceNumber].vue'),
        /* no children */
      },
      {
        path: ':orderId/payments/new',
        name: '/demand-orders/[orderId].payments.new',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/demand-orders/[orderId].payments.new.vue'),
        /* no children */
      },
      {
        path: 'new',
        name: '/demand-orders/new',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/demand-orders/new.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/due-diligence',
    /* internal name: '/due-diligence' */
    /* no component */
    children: [
      {
        path: 'form',
        /* internal name: '/due-diligence/form' */
        /* no component */
        children: [
          {
            path: '',
            name: '/due-diligence/form/',
            component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/due-diligence/form/index.vue'),
            /* no children */
          },
          {
            path: ':category',
            name: '/due-diligence/form/[category]',
            component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/due-diligence/form/[category].vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'form-weights',
        name: '/due-diligence/form-weights',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/due-diligence/form-weights.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/export',
    /* internal name: '/export' */
    /* no component */
    children: [
      {
        path: '',
        name: '/export/',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/export/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/impact-accounts',
    /* internal name: '/impact-accounts' */
    /* no component */
    children: [
      {
        path: '',
        name: '/impact-accounts/',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/impact-accounts/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        name: '/impact-accounts/[id]',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/impact-accounts/[id].vue'),
        /* no children */
      },
      {
        path: 'new',
        name: '/impact-accounts/new',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/impact-accounts/new.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/projects',
    /* internal name: '/projects' */
    /* no component */
    children: [
      {
        path: '',
        name: '/projects/',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/projects/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        name: '/projects/[id]',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/projects/[id].vue'),
        /* no children */
      },
      {
        path: ':id/due-diligence',
        name: '/projects/[id].due-diligence',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/projects/[id].due-diligence.vue'),
        /* no children */
      },
      {
        path: ':id/due-diligence/review',
        name: '/projects/[id].due-diligence.review',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/projects/[id].due-diligence.review.vue'),
        /* no children */
      },
      {
        path: 'new',
        name: '/projects/new',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/projects/new.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/removal-methods',
    /* internal name: '/removal-methods' */
    /* no component */
    children: [
      {
        path: '',
        name: '/removal-methods/',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/removal-methods/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        name: '/removal-methods/[id]',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/removal-methods/[id].vue'),
        /* no children */
      },
      {
        path: 'new',
        name: '/removal-methods/new',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/removal-methods/new.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/suppliers',
    /* internal name: '/suppliers' */
    /* no component */
    children: [
      {
        path: '',
        name: '/suppliers/',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/suppliers/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        name: '/suppliers/[id]',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/suppliers/[id].vue'),
        /* no children */
      },
      {
        path: 'new',
        name: '/suppliers/new',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/suppliers/new.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/supply-inventory',
    /* internal name: '/supply-inventory' */
    /* no component */
    children: [
      {
        path: '',
        name: '/supply-inventory/',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/supply-inventory/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/supply-orders',
    /* internal name: '/supply-orders' */
    /* no component */
    children: [
      {
        path: '',
        name: '/supply-orders/',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/supply-orders/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        name: '/supply-orders/[id]',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/supply-orders/[id].vue'),
        /* no children */
      },
      {
        path: ':orderId/items/:itemId',
        name: '/supply-orders/[orderId].items.[itemId]',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/supply-orders/[orderId].items.[itemId].vue'),
        /* no children */
      },
      {
        path: ':orderId/items/new',
        name: '/supply-orders/[orderId].items.new',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/supply-orders/[orderId].items.new.vue'),
        /* no children */
      },
      {
        path: ':orderId/payments/:paymentId',
        name: '/supply-orders/[orderId].payments.[paymentId]',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/supply-orders/[orderId].payments.[paymentId].vue'),
        /* no children */
      },
      {
        path: ':orderId/payments/new',
        name: '/supply-orders/[orderId].payments.new',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/supply-orders/[orderId].payments.new.vue'),
        /* no children */
      },
      {
        path: 'new',
        name: '/supply-orders/new',
        component: () => import('/home/runner/work/api-klimate/api-klimate/admin-frontend/src/pages/supply-orders/new.vue'),
        /* no children */
      }
    ],
  }
]
