<template>
  <TransitionRoot as="template" :show="sidebarOpen">
    <Dialog
      as="div"
      :class="['relative z-10', !useNavDrawer && 'lg:hidden']"
      @close="sidebarOpen = false"
    >
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-900/80" />
      </TransitionChild>

      <div class="fixed inset-0 flex">
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                  <span class="sr-only">Close sidebar</span>
                  <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
              <div class="mt-5 flex shrink-0 flex-col justify-center">
                <RouterLink to="/">
                  <AppLogo
                    :class="[
                      'mb-3 h-7 w-auto',
                      environment === 'production'
                        ? 'fill-gray-900'
                        : environment === 'development'
                          ? 'fill-red-600'
                          : 'fill-indigo-600',
                    ]"
                  />
                </RouterLink>
                <div
                  v-if="environment !== 'production'"
                  :class="[
                    'rounded-md py-1 text-center text-xs font-bold uppercase',
                    environment === 'development'
                      ? 'bg-red-100 text-red-600'
                      : 'bg-indigo-100 text-indigo-600',
                  ]"
                >
                  {{ environment }}
                </div>
              </div>
              <nav class="flex flex-1 flex-col">
                <ul role="list" class="flex flex-1 flex-col gap-y-7">
                  <li>
                    <ul role="list" class="-mx-2 space-y-1">
                      <li v-for="item in mainSection" :key="item.name">
                        <RouterLink
                          v-if="!item.external"
                          :to="item.to"
                          :class="[
                            item.to === route.path
                              ? 'bg-gray-50 text-indigo-600'
                              : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                            'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                          ]"
                          @click="sidebarOpen = false"
                        >
                          <component
                            :is="item.icon"
                            :class="[
                              item.to === route.path
                                ? 'text-indigo-600'
                                : 'text-gray-400 group-hover:text-indigo-600',
                              'h-6 w-6 shrink-0',
                            ]"
                            aria-hidden="true"
                          />
                          {{ item.name }}
                        </RouterLink>
                        <a
                          v-else
                          :href="item.href"
                          class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                        >
                          <component
                            :is="item.icon"
                            class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                            aria-hidden="true"
                          />
                          {{ item.name }}
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li v-for="section in sections" :key="section.title">
                    <div class="text-xs font-semibold leading-6 text-gray-400">
                      {{ section.title }}
                    </div>
                    <ul role="list" class="-mx-2 mt-2 space-y-1">
                      <li v-for="item in section.items" :key="item.name">
                        <RouterLink
                          v-if="!item.external"
                          :to="item.to"
                          :class="[
                            item.to === route.path
                              ? 'bg-gray-50 text-indigo-600'
                              : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                            'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                          ]"
                          @click="sidebarOpen = false"
                        >
                          <component
                            :is="item.icon"
                            :class="[
                              item.to === route.path
                                ? 'text-indigo-600'
                                : 'text-gray-400 group-hover:text-indigo-600',
                              'h-6 w-6 shrink-0',
                            ]"
                            aria-hidden="true"
                          />
                          {{ item.name }}
                        </RouterLink>
                        <a
                          v-else
                          :href="item.href"
                          class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                        >
                          <component
                            :is="item.icon"
                            class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                            aria-hidden="true"
                          />
                          {{ item.name }}
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <button
                  class="absolute bottom-2 right-2 hidden rounded-md bg-white p-1 hover:bg-gray-100 lg:block"
                  title="Expand Navbar"
                  @click="useNavDrawer = false"
                >
                  <ChevronDoubleRightIcon class="h-6 w-6" />
                </button>
              </nav>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>

  <!-- Static sidebar for desktop -->
  <div
    :class="[
      'hidden lg:fixed lg:inset-y-0 lg:z-10 lg:w-72 lg:flex-col',
      !useNavDrawer && 'lg:flex',
    ]"
  >
    <!-- Sidebar component, swap this element with another sidebar if you like -->
    <div class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
      <div class="mt-5 flex shrink-0 flex-col justify-center">
        <RouterLink to="/">
          <AppLogo
            :class="[
              'mb-3 h-7 w-auto',
              environment === 'production'
                ? 'fill-gray-900'
                : environment === 'development'
                  ? 'fill-red-600'
                  : 'fill-indigo-600',
            ]"
          />
        </RouterLink>
        <div
          v-if="environment !== 'production'"
          :class="[
            'rounded-md py-1 text-center text-xs font-bold uppercase',
            environment === 'development'
              ? 'bg-red-100 text-red-600'
              : 'bg-indigo-100 text-indigo-600',
          ]"
        >
          {{ environment }}
        </div>
      </div>
      <nav class="flex flex-1 flex-col">
        <ul role="list" class="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" class="-mx-2 space-y-1">
              <li v-for="item in mainSection" :key="item.name">
                <RouterLink
                  v-if="!item.external"
                  :to="item.to"
                  :class="[
                    item.to === route.path
                      ? 'bg-gray-50 text-indigo-600'
                      : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                    'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                  ]"
                >
                  <component
                    :is="item.icon"
                    :class="[
                      item.to === route.path
                        ? 'text-indigo-600'
                        : 'text-gray-400 group-hover:text-indigo-600',
                      'h-6 w-6 shrink-0',
                    ]"
                    aria-hidden="true"
                  />
                  {{ item.name }}
                </RouterLink>
                <a
                  v-else
                  :href="item.href"
                  class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                >
                  <component
                    :is="item.icon"
                    class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                    aria-hidden="true"
                  />
                  {{ item.name }}
                </a>
              </li>
            </ul>
          </li>
          <li v-for="section in sections" :key="section.title">
            <div class="text-xs font-semibold leading-6 text-gray-400">{{ section.title }}</div>
            <ul role="list" class="-mx-2 mt-2 space-y-1">
              <li v-for="item in section.items" :key="item.name">
                <RouterLink
                  v-if="!item.external"
                  :to="item.to"
                  :class="[
                    item.to === route.path
                      ? 'bg-gray-50 text-indigo-600'
                      : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                    'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                  ]"
                >
                  <component
                    :is="item.icon"
                    :class="[
                      item.to === route.path
                        ? 'text-indigo-600'
                        : 'text-gray-400 group-hover:text-indigo-600',
                      'h-6 w-6 shrink-0',
                    ]"
                    aria-hidden="true"
                  />
                  {{ item.name }}
                </RouterLink>
                <a
                  v-else
                  :href="item.href"
                  class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                >
                  <component
                    :is="item.icon"
                    class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                    aria-hidden="true"
                  />
                  {{ item.name }}
                </a>
              </li>
            </ul>
          </li>
          <li v-if="user" class="-mx-6 mt-auto">
            <button
              @click="logout()"
              class="flex w-full items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50"
            >
              <img
                v-if="user.picture"
                class="h-8 w-8 rounded-full bg-gray-50"
                :src="user.picture"
                alt=""
              />
              <span
                v-else
                class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500"
              >
                <span class="text-sm font-medium leading-none text-white">
                  {{ user.given_name?.slice(0, 1).toUpperCase() ?? 'X' }}
                </span>
              </span>
              <span class="sr-only">Your profile</span>
              <!-- In practice, user.email is never undefined -->
              <span aria-hidden="true">{{ user.given_name ?? user.email }}</span>
            </button>
          </li>
        </ul>
        <button
          class="absolute bottom-2 right-2 rounded-md bg-white p-1 hover:bg-gray-100"
          title="Collapse Navbar"
          @click="useNavDrawer = true"
        >
          <ChevronDoubleLeftIcon class="h-6 w-6" />
        </button>
      </nav>
    </div>
  </div>

  <div
    :class="[
      'sticky top-0 z-10 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6',
      !useNavDrawer && 'lg:hidden',
    ]"
  >
    <button type="button" class="-m-2.5 p-2.5 text-gray-700" @click="sidebarOpen = true">
      <span class="sr-only">Open sidebar</span>
      <Bars3Icon class="h-6 w-6" aria-hidden="true" />
    </button>
    <div class="flex-1 text-sm font-semibold leading-6 text-gray-900">{{ title }}</div>
    <button v-if="user" @click="logout()">
      <span class="sr-only">Your profile</span>
      <img
        v-if="user.picture"
        class="h-8 w-8 rounded-full bg-gray-50"
        :src="user.picture"
        alt=""
        referrerpolicy="no-referrer"
      />
      <span v-else class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
        <span class="text-sm font-medium leading-none text-white">
          {{ user.given_name?.slice(0, 1).toUpperCase() ?? 'X' }}
        </span>
      </span>
    </button>
  </div>

  <main :class="['h-full px-4 sm:px-6 lg:px-8', !useNavDrawer && 'lg:pl-72']">
    <RouterView />
  </main>

  <AppNotifications />
</template>

<script setup lang="ts">
import { useAuth0 } from '@auth0/auth0-vue';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/vue/20/solid';
import {
  ArrowDownTrayIcon,
  ArrowTrendingDownIcon,
  Bars3Icon,
  BoltIcon,
  ClipboardDocumentListIcon,
  ClipboardIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  GlobeAmericasIcon,
  PencilSquareIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
  TableCellsIcon,
  TruckIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline';
import { useFavicon } from '@vueuse/core';
import { type Component, ref, toRefs } from 'vue';
import { type RouteLocationRaw, RouterLink, RouterView, useRoute } from 'vue-router/auto';

import AppLogo from '@/components/AppLogo.vue';
import AppNotifications from '@/components/AppNotifications.vue';
import { useTitleStore } from '@/stores/title';

type Link =
  | { name: string; icon: Component; href: string; external: true }
  | { name: string; icon: Component; to: RouteLocationRaw; external: false };
type Section = { title: string; items: Link[] };

const mainSection: Link[] = [
  {
    name: 'Supply Inventory',
    to: '/supply-inventory',
    icon: TableCellsIcon,
    external: false,
  },
];
const sections: Section[] = [
  {
    title: 'Supply',
    items: [
      { name: 'Suppliers', to: '/suppliers', icon: TruckIcon, external: false },
      { name: 'Projects', to: '/projects', icon: FolderIcon, external: false },
      {
        name: 'Supply Orders',
        to: '/supply-orders',
        icon: DocumentDuplicateIcon,
        external: false,
      },
      {
        name: 'Removal Methods',
        to: '/removal-methods',
        icon: BoltIcon,
        external: false,
      },
    ],
  },
  {
    title: 'Demand',
    items: [
      { name: 'Clients', to: '/clients', icon: UsersIcon, external: false },
      {
        name: 'Portfolio Templates',
        to: '/composition-templates',
        icon: ClipboardIcon,
        external: false,
      },
      {
        name: 'Demand Orders',
        to: '/demand-orders',
        icon: ClipboardDocumentListIcon,
        external: false,
      },
      {
        name: 'Impact Accounts',
        to: '/impact-accounts',
        icon: ArrowTrendingDownIcon,
        external: false,
      },
      {
        name: 'Calculation Methods',
        to: '/calculation-methods',
        icon: PlusIcon,
        external: false,
      },
    ],
  },
  {
    title: 'Due Diligence',
    items: [
      {
        name: 'Form',
        to: '/due-diligence/form',
        icon: PencilSquareIcon,
        external: false,
      },
    ],
  },
  {
    title: 'Tools',
    items: [
      {
        name: 'Export',
        to: '/export',
        icon: ArrowDownTrayIcon,
        external: false,
      },
      {
        name: 'Public Registry',
        href: 'https://registry.klimate.co',
        icon: GlobeAmericasIcon,
        external: true,
      },
    ],
  },
  {
    title: 'Help',
    items: [
      {
        name: 'Help',
        href: 'https://www.notion.so/klimate/Portfolio-Order-4d69153ad64e4301baf70411a20bcb4e?pvs=4',
        icon: QuestionMarkCircleIcon,
        external: true,
      },
    ],
  },
];

const { logout: auth0Logout, user } = useAuth0();
const sidebarOpen = ref(false);
const useNavDrawer = ref(false);
const route = useRoute();
const { title } = toRefs(useTitleStore());
const logout = () => {
  auth0Logout({ logoutParams: { returnTo: import.meta.env.VITE_AUTH0_CALLBACK_URL } });
};

const environment = import.meta.env.MODE;
if (environment === 'development') useFavicon('/img/icons/favicon-red.svg');
</script>
