import { authGuard } from '@auth0/auth0-vue';
import { createRouter, createWebHistory } from 'vue-router/auto';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  extendRoutes(routes) {
    routes.push({
      name: 'Admin redirect',
      path: '/admin/:path(.*)',
      redirect(to) {
        return '/' + to.params.path;
      },
    });
    return routes;
  },
});
router.beforeEach((to) => {
  if (!to.path.startsWith('/public')) {
    return authGuard(to);
  } else {
    window.location.replace(import.meta.env.VITE_REGISTRY_URL + to.path.substring(7));
  }
});

export default router;
